define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-custom-fields", ["exports", "@ember/controller", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-custom-field"], function (_exports, _controller, _customWizardCustomField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    messageKey: "create",
    fieldKeys: ["klass", "type", "name", "serializers"],
    documentationUrl: "https://discourse.pluginmanager.org/t/custom-fields",
    actions: {
      addField() {
        this.get("customFields").unshiftObject(_customWizardCustomField.default.create({
          edit: true
        }));
      },
      saveField(field) {
        return _customWizardCustomField.default.saveField(field).then(result => {
          if (result.success) {
            this.setProperties({
              messageKey: "saved",
              messageType: "success"
            });
          } else {
            if (result.messages) {
              this.setProperties({
                messageKey: "error",
                messageType: "error",
                messageOpts: {
                  messages: result.messages
                }
              });
            }
          }
          setTimeout(() => this.setProperties({
            messageKey: "create",
            messageType: null,
            messageOpts: null
          }), 10000);
          return result;
        });
      },
      removeField(field) {
        return _customWizardCustomField.default.destroyField(field).then(() => {
          this.get("customFields").removeObject(field);
        });
      }
    }
  });
});